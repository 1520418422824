import React, {useState} from "react"
import Layout from "../components/layout"

export default () => {
    const [show,setShow] = useState(false);

    return (
        <Layout pageId={"/info"}>
            <h1 className="text-center my-5">
                付费资讯
            </h1>
            <div className="container my-5">
                <div style={{display: "flex", flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', }}>
                    <div onClick={() => {setShow(true)}} className={'news-item'} style={{padding: 10,display: "flex", width: '70%', height: 50, justifyContent: 'space-between', alignItems: 'center'}}>
                        <div style={{color: '#000000', fontSize: 22, fontWeight: 'bold', width: 50}}>1、</div>
                        <div style={{flex: 1, color: '#000000', fontSize: 20, fontWeight: 'bold'}}>怎样采集数据支持在ai算法下完成图案演化</div>
                        <div style={{color: '#999999', fontSize: 18}}>2022-10-02</div>
                    </div>
                    <div onClick={() => {setShow(true)}} className={'news-item'} style={{padding: 10,display: "flex", width: '70%', height: 50, justifyContent: 'space-between', alignItems: 'center'}}>
                        <div style={{color: '#000000', fontSize: 22, fontWeight: 'bold', width: 50}}>2、</div>
                        <div style={{flex: 1, color: '#000000', fontSize: 20, fontWeight: 'bold'}}>如何用Datoloader同时加载三个数据集呢</div>
                        <div style={{color: '#999999', fontSize: 18}}>2022-10-08</div>
                    </div>
                    <div onClick={() => {setShow(true)}} className={'news-item'} style={{padding: 10,display: "flex", width: '70%', height: 50, justifyContent: 'space-between', alignItems: 'center'}}>
                        <div style={{color: '#000000', fontSize: 22, fontWeight: 'bold', width: 50}}>3、</div>
                        <div style={{flex: 1, color: '#000000', fontSize: 20, fontWeight: 'bold'}}>添加ftp站点后无法通过访问ip地址打开网页</div>
                        <div style={{color: '#999999', fontSize: 18}}>2022-10-10</div>
                    </div>
                    <div onClick={() => {setShow(true)}} className={'news-item'} style={{padding: 10,display: "flex", width: '70%', height: 50, justifyContent: 'space-between', alignItems: 'center'}}>
                        <div style={{color: '#000000', fontSize: 22, fontWeight: 'bold', width: 50}}>4、</div>
                        <div style={{flex: 1, color: '#000000', fontSize: 20, fontWeight: 'bold'}}>如何在R软件中使用survivalROC软件包计算AUC的置信区间</div>
                        <div style={{color: '#999999', fontSize: 18}}>2022-10-12</div>
                    </div>
                    <div onClick={() => {setShow(true)}} className={'news-item'} style={{padding: 10,display: "flex", width: '70%', height: 50, justifyContent: 'space-between', alignItems: 'center'}}>
                        <div style={{color: '#000000', fontSize: 22, fontWeight: 'bold', width: 50}}>5、</div>
                        <div style={{flex: 1, color: '#000000', fontSize: 20, fontWeight: 'bold'}}>C++ SetUnHandledExceptionFilter问题</div>
                        <div style={{color: '#999999', fontSize: 18}}>2022-10-14</div>
                    </div>
                    <div onClick={() => {setShow(true)}} className={'news-item'} style={{padding: 10,display: "flex", width: '70%', height: 50, justifyContent: 'space-between', alignItems: 'center'}}>
                        <div style={{color: '#000000', fontSize: 22, fontWeight: 'bold', width: 50}}>6、</div>
                        <div style={{flex: 1, color: '#000000', fontSize: 20, fontWeight: 'bold'}}>TCPING命令和数据库的相关问题</div>
                        <div style={{color: '#999999', fontSize: 18}}>2022-10-14</div>
                    </div>
                    <div onClick={() => {setShow(true)}} className={'news-item'} style={{padding: 10,display: "flex", width: '70%', height: 50, justifyContent: 'space-between', alignItems: 'center'}}>
                        <div style={{color: '#000000', fontSize: 22, fontWeight: 'bold', width: 50}}>7、</div>
                        <div style={{flex: 1, color: '#000000', fontSize: 20, fontWeight: 'bold'}}>计算机网络配置完整过程</div>
                        <div style={{color: '#999999', fontSize: 18}}>2022-10-16</div>
                    </div>
                    <div onClick={() => {setShow(true)}} className={'news-item'} style={{padding: 10,display: "flex", width: '70%', height: 50, justifyContent: 'space-between', alignItems: 'center'}}>
                        <div style={{color: '#000000', fontSize: 22, fontWeight: 'bold', width: 50}}>8、</div>
                        <div style={{flex: 1, color: '#000000', fontSize: 20, fontWeight: 'bold'}}>安卓webview的dom加载完成的事件</div>
                        <div style={{color: '#999999', fontSize: 18}}>2022-10-19</div>
                    </div>
                    <div onClick={() => {setShow(true)}} className={'news-item'} style={{padding: 10,display: "flex", width: '70%', height: 50, justifyContent: 'space-between', alignItems: 'center'}}>
                        <div style={{color: '#000000', fontSize: 22, fontWeight: 'bold', width: 50}}>9、</div>
                        <div style={{flex: 1, color: '#000000', fontSize: 20, fontWeight: 'bold'}}>在胶囊网络的训练过程中间歇性出问题</div>
                        <div style={{color: '#999999', fontSize: 18}}>2022-10-19</div>
                    </div>
                    <div onClick={() => {setShow(true)}} className={'news-item'} style={{padding: 10,display: "flex", width: '70%', height: 50, justifyContent: 'space-between', alignItems: 'center'}}>
                        <div style={{color: '#000000', fontSize: 22, fontWeight: 'bold', width: 50}}>10、</div>
                        <div style={{flex: 1, color: '#000000', fontSize: 20, fontWeight: 'bold'}}>Meta分析t值、f值如何转换为r值？</div>
                        <div style={{color: '#999999', fontSize: 18}}>2022-10-20</div>
                    </div>
                </div>
            </div>
            {
                show && <div style={{position: "fixed",top: 0, backgroundColor: 'rgba(0, 0, 0, 0.8)', zIndex: 999, width: '100%', height: '100vh'}}>
                    <div style={{display: 'flex', width: '100%',height: '100%', justifyContent: 'center', alignItems: 'center',}}>
                        <div style={{backgroundColor: '#ffffff', position: "relative", justifyContent: 'center', alignItems: 'center', width: 300, height: 350, borderRadius: 10}}>
                            <div style={{textAlign: 'center', height: 50, lineHeight: '50px', fontWeight: 'bold', fontSize: 18, color: '#222222'}}>扫码支付（￥2元）</div>
                            <img src="/images/payQRCode.png" alt="二维码" style={{width: 300, height:'auto'}}/>
                            <div style={{position: "absolute", top: -50, right: -50}} onClick={() => {setShow(false)}}>
                                <img src="/images/close.png" style={{width: 40, height: 40}}/>
                            </div>
                        </div>
                    </div>

                </div>
            }

        </Layout>
    )
}

